const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://urbandrive.dvgeo.app' : 'http://192.168.1.106:3050',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://urbandrive.dvgeo.app' : 'http://192.168.1.106:3050',
    api: 'api/',
    apiSocket: 'urbandrive::1.2',
    nameDir: 'urbandrive',
    package: 'app.dvgeo.urbandrive.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyA6faOufwUjDgxTFNUndc9vOxaAtGz2x5Y',
    appName: 'UrbanDrive',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#ffa300',
    colorDark: '#183f9c',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.urbandrive.passenger',
    playStoreDriverId: 'app.dvgeo.urbandrive.driver',
    appStorePassengerId: '6451614281',
    appStoreDriverId: '6451614351',
    email: "urbandriverpopayan@gmail.com",
};
export default config;
